<template>
  <ion-row>
    <ion-col>
      <button @click="handleClick" class="prime-button button-block enroll-button" :class="{ 'button-pending': isLoading }" type="submit">
        <span>{{ submitButtonText }}</span>
        <ion-spinner name="dots" duration="1200" />
      </button>
    </ion-col>
  </ion-row>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import isValidPhoneNumber from "@/utils/FormHelpers/isValidPhoneNumber";
import formatPhoneNumber from "@/utils/FormHelpers/formatPhoneNumber";
import isValidEmail from "@/utils/FormHelpers/isValidEmail";

export default {
  name: "SubmitEnrollment",
  props: {
    isDenial: Boolean,
    isApproval: Boolean,
    enrollment: Object,
    enrollmentWrapper: Object,
    restMethod: String,
    url: String,
    token: String,
    copy: Object
  },
  data() {
    return {
      isLoading: false,
      successText: ""
    };
  },
  computed: {
    submitButtonText() {
      if (this.isApproval) {
        return "Approve";
      }
      if (this.isDenial) {
        return "Deny";
      }
      return "Enroll Now";
    }
  },
  methods: {
    handleClick() {
      this.$emit("submitClick");
    },
    checkForm() {
      const isApproval = this.isApproval;
      const isDenial = this.isDenial;
      const schema = {
        eula: value => isApproval || isDenial || (value && value === true),
        email: value => isValidEmail(value),
        firstName: value => value && /[a-zA-Z]/.test(value),
        lastName: value => value && /[a-zA-Z]/.test(value),
        smsPhoneNumber: value => value && isValidPhoneNumber(value),
        timeZone: value => value,
        voicePhoneNumber: value => value && isValidPhoneNumber(value)
      };
      const validation = Object.entries(schema).map(([key, validationFunction]) => {
        if (this.enrollment.hasOwnProperty(key)) {
          return validationFunction(this.enrollment[key]) ? true : this.copy.validationErrors[key];
        }
        const returnMsg = this.copy.validationErrors[key] ? this.copy.validationErrors[key] : "Please enter a valid " + key;
        return returnMsg;
      });

      // validation array will have either true or error string, so filter out trues to get errors
      const errors = validation.filter(b => b !== true);

      if (errors.length > 0) {
        this.$emit("error", errors);
      } else {
        this.submit();
      }
    },
    submit() {
      const isDenial = this.isDenial;
      this.successText = this.isApproval ? this.copy.successText.approval : this.copy.successText.enrolled;
      this.enrollment.smsPhoneNumber = formatPhoneNumber(this.enrollment.smsPhoneNumber);
      this.enrollment.voicePhoneNumber = formatPhoneNumber(this.enrollment.voicePhoneNumber);

      if (isDenial) {
        this.$ionic.alertController
          .create({
            header: "Confirm Denial",
            message: "Are you sure you want to decline this patient's enrollment in LumerisEngage?",
            mode: "ios",
            buttons: [
              {
                text: "No",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {}
              },
              {
                text: "Yes",
                handler: () => {
                  this.sendEnrollment();
                }
              }
            ]
          })
          .then(a => a.present());
      } else {
        this.sendEnrollment();
      }
    },
    async sendEnrollment() {
      const path = !this.isApproval && this.enrollment.phoneCallRequested ? document.config.enrollmentApiPublic + "callRequested/" + this.token : this.url + this.token;
      const method = this.restMethod;
      let payload;

      if (this.isApproval) {
        const claims = await this.$authState.claims();
        this.enrollmentWrapper.status = "approved";
        this.enrollmentWrapper.approvedBy = claims.professionalId;
        this.enrollmentWrapper.approvedAt = new Date().toISOString();
        this.enrollmentWrapper.attributes = this.enrollment;
        payload = this.enrollmentWrapper;
      } else if (this.isDenial) {
        const claims = await this.$authState.claims();
        this.enrollmentWrapper.status = "denied";
        this.enrollmentWrapper.approvedBy = claims.professionalId;
        this.enrollmentWrapper.approvedAt = new Date().toISOString();
        this.enrollmentWrapper.attributes = this.enrollment;
        payload = this.enrollmentWrapper;
      } else {
        payload = this.enrollment;
      }
      this.isLoading = true;
      httpSend({
        path,
        method,
        payload,
        authToken: this.isApproval || this.isDenial
      })
        .then(() =>
          this.$router.push({
            name: "success",
            params: {
              title: this.isDenial ? "Completed" : "Success",
              body: this.isDenial ? this.denialText : this.successText,
              navlink: this.isApproval || this.isDenial ? '<a href="/professional/roster">Back to Roster</a>' : "You may close this window."
            }
          })
        )
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not send enrollment. Please try again later.",
              message: error,
              duration: 10000,
              position: "top"
            })
            .then(m => m.present());
          this.isLoading = false;
          khanSolo.log(error);
        });
    }
  }
};
</script>

<style scoped>
.enroll-button {
  margin: 10px 0px;
}
</style>
