<template>
  <ion-grid fixed="true" class="prime-enrollment-container">
    <ion-row>
      <ion-col>
        <EnrollmentFormHeader
          v-bind="{
            isApproval,
            copy: pageCopy.enrollHeader
          }"
        />
        <div class="form">
          <FormErrors
            ref="enrollmentFormErrors"
            v-bind="{
              errors,
              copy: pageCopy.formErrors
            }"
          />
          <form @submit.prevent="" ref="enrollmentForm" novalidate="true" class="login-form">
            <ion-grid>
              <hr class="margin-bottom-twenty" />
              <GeneralInformation
                class="prime-enrollment-form-section"
                @input="handleInput($event)"
                v-bind="{
                  firstName: enrollment.firstName,
                  lastName: enrollment.lastName,
                  suffix: enrollment.suffix,
                  copy: pageCopy.generalInformation,
                  isApproval,
                  callRequested
                }"
              />
              <CommunicationPrefContact
                class="prime-enrollment-form-section"
                @input="handleInput($event)"
                v-bind="{
                  email: enrollment.email,
                  smsPhoneNumber: enrollment.smsPhoneNumber,
                  voicePhoneNumber: enrollment.voicePhoneNumber,
                  copy: pageCopy.communicationPrefContact
                }"
              />
              <CommunicationPrefTimes
                class="prime-enrollment-form-section"
                @input="handleInput($event)"
                v-bind="{
                  copy: pageCopy.communicationPrefTimes,
                  timeZone: enrollment.timeZone
                }"
              />
              <SubmitEnrollment
                class="prime-enrollment-form-section"
                @error="handleErrors($event)"
                @submitClick="handleSubmitClick('enrollmentFormApprove')"
                ref="enrollmentFormApprove"
                v-bind="{
                  isApproval,
                  isDenial: false,
                  enrollment,
                  enrollmentWrapper,
                  restMethod,
                  url,
                  token,
                  copy: pageCopy.submitEnrollment
                }"
              />
              <SubmitEnrollment
                class="prime-enrollment-form-section"
                v-if="isApproval"
                @error="handleErrors($event)"
                @submitClick="handleSubmitClick('enrollmentFormDeny')"
                ref="enrollmentFormDeny"
                v-bind="{
                  isDenial: true,
                  enrollment,
                  enrollmentWrapper,
                  restMethod,
                  url,
                  token,
                  copy: pageCopy.submitEnrollment
                }"
              />
            </ion-grid>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { addIcons } from "ionicons";
import { alert } from "ionicons/icons";

import EnrollmentFormHeader from "@/components/Enrollment/FormHeaderEnrollment";
import FormErrors from "@/components/Enrollment/FormErrors";
import GeneralInformation from "@/components/Enrollment/GeneralInformation";
import CommunicationPrefContact from "@/components/Enrollment/CommunicationPrefContact";
import CommunicationPrefTimes from "@/components/Enrollment/CommunicationPrefTimes";
import SubmitEnrollment from "@/components/Enrollment/SubmitEnrollment";
import AuthState from "@/utils/Auth";

import { send as httpSend } from "@/services/Api";

addIcons({
  "ios-alert": alert.ios,
  "md-alert": alert.md
});

export default {
  name: "FormEnrollment",
  components: {
    EnrollmentFormHeader,
    FormErrors,
    GeneralInformation,
    CommunicationPrefContact,
    CommunicationPrefTimes,
    SubmitEnrollment
  },
  props: {
    restMethod: String,
    isApproval: Boolean,
    url: String,
    authToken: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      errors: [],
      callRequested: false,
      token: "",
      isLoading: false,
      smsNumber: "",
      enrollment: {},
      enrollmentWrapper: {},
      submitContext: "",
      pageCopy: this.$languagePack.enrollmentForm
    };
  },
  created() {
    this.token = this.$route.query.token;
    this.getEnrollmentDetails();
  },
  methods: {
    handleInput(event) {
      this.$set(this.enrollment, event.name, event.value);
    },
    handleErrors(errors) {
      this.errors = errors;
      document.querySelector("ion-content").scrollToTop();
    },
    handleSubmitClick(context) {
      this.errors = [];
      this.$refs[context].checkForm();
    },
    async getEnrollmentDetails() {
      const method = "GET";
      const path = this.url + this.token;
      try {
        const claims = await AuthState.claims();

        const result = await httpSend({ path, method, authToken: this.isApproval });

        const enrollment = this.isApproval ? result.data.payload.attributes : result.data.payload;
        enrollment.timeZone = enrollment.timeZone ? enrollment.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
        enrollment.eula = true;
        enrollment.privacyPolicy = true;
        enrollment.communicationsPolicy = true;
        enrollment.electronicSignatureProfessional = claims.professionalId;
        this.callRequested = enrollment.phoneCallRequested;
        this.smsNumber = this.isApproval ? result.data.payload.sms_phone_number : "";
        enrollment.voicePhoneNumber = enrollment.voicePhoneNumber || enrollment.smsPhoneNumber;

        this.enrollment = enrollment;
        this.enrollmentWrapper = this.isApproval ? result.data.payload : {};
      } catch (error) {
        // add defaults that cause issues if not present
        this.enrollment = {
          electronicSignatureProfessional: null
        };
        this.enrollmentWrapper = {};
      }
    }
  }
};
</script>

<style>
.prime-enrollment-container {
  max-width: 740px;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 80px;
  --ion-grid-width-sm: 100%;
}

.prime-enrollment-container h4 {
  margin-top: 50px;
  margin-bottom: 30px;
}
.prime-enrollment-container .prime-enrollment-form-section {
  width: 650px;
  margin: 0 auto;
}

.prime-form-large-group-container {
  margin-bottom: 30px;
  display: flex;
}
@media only screen and (max-width: 768px) {
  .prime-enrollment-container .prime-enrollment-form-section {
    width: 100%;
  }
  .prime-form-large-group-container {
    flex-wrap: wrap;
  }
  .prime-form-large-group-container .prime-form-large-group-fields.large-block {
    text-align: left !important;
    line-height: 3;
  }
}
.prime-form-large-group-container .prime-form-large-group-info {
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 20px;
}
.prime-form-large-group-container .prime-form-large-group-info h6 {
  margin-top: 0 !important;
}
.prime-form-large-group-container .prime-form-large-group-fields {
  display: flex;
  flex: 1 0 220px;
}
.prime-form-large-group-container .prime-form-large-group-fields input {
  height: 40px;
}
.prime-form-large-group-container .prime-form-large-group-fields.large {
  display: flex;
  flex: 1 0 260px;
}
.prime-form-large-group-container .prime-form-large-group-fields.large select {
  max-width: 75px;
}
.prime-form-large-group-container .prime-form-large-group-fields.large select:only-child {
  max-width: 100%;
}
.prime-form-large-group-container .prime-form-large-group-fields.large > * {
  margin-right: 10px;
}
.prime-form-large-group-container .prime-form-large-group-fields.large > *:last-child {
  margin-right: 0;
}
.prime-form-large-group-container.prime-flex-wrap {
  flex-wrap: wrap;
}
.prime-form-large-group-container .prime-form-large-group-fields.large-block {
  margin-top: 5px;
  text-align: right;
  display: block;
  flex: 0 0 100%;
}
.prime-form-large-group-container .prime-form-large-group-fields.large-block select {
  max-width: 95px;
  display: inline;
  margin: 0 10px;
}

.recaptcha div {
  margin: 0 auto;
}

.alert-icon {
  color: rgb(205, 0, 0);
  font-size: 50px;
}

.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #4caf50;
  text-decoration: none;
}

.bottom-margin {
  margin-bottom: 10px;
}
.grid-row-padding-ten ion-col {
  margin: 0 5px;
}
.grid-row-padding-ten ion-col:first-child {
  margin-left: 0px;
}
.grid-row-padding-ten ion-col:last-child {
  margin-right: 0px;
}

@media only screen and (max-width: 600px) {
  #app h2 {
    font-size: 30px;
  }
  .grid-row-padding-ten ion-col:first-child {
    margin: 0 0 10px 0;
  }
  .grid-row-padding-ten ion-col:last-child {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 575px) {
  .grid-row-padding-twenty ion-col {
    margin: 0 10px;
  }
  .grid-row-padding-twenty ion-col:first-child {
    margin-left: 0px;
  }
  .grid-row-padding-twenty ion-col:last-child {
    margin-right: 0px;
  }
}
</style>
