<template>
  <div>
    <h4>General Information</h4>
    <div class="prime-form-large-group-container">
      <div class="prime-form-large-group-info">
        <h6 class="form-field-label">First Name</h6>
        <p class="text-muted">
          Your legal first name and preferred prefix (optional).
        </p>
      </div>
      <div class="prime-form-large-group-fields large">
        <input id="firstName" class="form-control" type="text" placeholder="First Name" :value="firstName" @input="emitInput($event)" />
      </div>
    </div>
    <div class="prime-form-large-group-container">
      <div class="prime-form-large-group-info">
        <h6 class="form-field-label">Last Name</h6>
        <p class="text-muted">
          Your legal last name and preferred suffix (optional).
        </p>
      </div>
      <div class="prime-form-large-group-fields large">
        <input id="lastName" class="form-control" type="text" placeholder="Last Name" :value="lastName" @input="emitInput($event)" />
        <select id="suffix" class="form-control" @change="emitInput($event)">
          <option v-for="option in copy.suffixOptions" :key="option" :selected="option === suffix" :value="option">{{ option }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralInformation",
  props: {
    title: String,
    suffix: String,
    firstName: String,
    lastName: String,
    pin: String,
    copy: Object,
    isApproval: Boolean,
    callRequested: Boolean
  },
  methods: {
    emitInput(event) {
      const emitEvent = {
        name: event.target.id,
        value: event.target.value
      };
      this.$emit("input", emitEvent);
    }
  }
};
</script>

<style scoped></style>
