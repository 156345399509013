<template>
  <div>
    <div class="prime-form-large-group-container">
      <div class="prime-form-large-group-info">
        <h6 class="form-field-label">Timezone</h6>
        <p class="text-muted">
          Select your local timezone, if different from the organization default.
        </p>
      </div>
      <div class="prime-form-large-group-fields large">
        <select id="timeZone" class="form-control" style="display:block; max-width:100%" @change="emitInput($event)">
          <option v-for="option in copy.timeZones" :key="option.label" :selected="option.value === timeZone" :value="option.value">{{ option.label }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommunicationPrefTimes",
  props: {
    copy: Object,
    timeZone: String
  },
  methods: {
    emitInput(event) {
      const emitEvent = {
        name: event.target.id,
        value: event.target.value
      };
      this.$emit("input", emitEvent);
    }
  }
};
</script>

<style scoped>
.display-block-on-mobile {
  display: inline;
}
@media screen and (max-width: 560px) {
  .display-block-on-mobile {
    display: block;
  }
}
</style>
