<template>
  <div class="prime-enroll-header">
    <img class="prime-logo-enrollment" src="../../assets/logo.svg" />
    <h2 class="text-center pad-lr-ten">{{ title }}</h2>
    <p v-if="!isApproval" class="pad-lr-ten text-muted">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "FormHeaderEnrollment",
  props: {
    isApproval: Boolean,
    copy: Object
  },
  data() {
    return {
      title: "",
      text: ""
    };
  },
  created() {
    this.title = this.isApproval ? this.copy.approvalHeader : this.copy.header;
  }
};
</script>

<style scoped>
.prime-enroll-header {
  display: flex;
  flex-direction: column;
}

img.prime-logo-enrollment {
  width: 280px;
  margin: 0 auto 40px;
}
</style>
