<template>
  <div v-if="errors.length" class="enrollment-form-error-container">
    <div v-for="error in errors" :key="error" class="error-notification">
      <IconWarning />
      <ion-text>{{ error }}</ion-text>
    </div>
  </div>
</template>

<script>
import IconWarning from "@/components/Global/Icons/IconWarning";
export default {
  name: "FormErrors",
  components: {
    IconWarning
  },
  props: {
    errors: Array
  }
};
</script>

<style scoped>
.enrollment-form-error-container {
  margin-bottom: 20px;
}
</style>
