var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ion-grid',{staticClass:"prime-enrollment-container",attrs:{"fixed":"true"}},[_c('ion-row',[_c('ion-col',[_c('EnrollmentFormHeader',_vm._b({},'EnrollmentFormHeader',{
          isApproval: _vm.isApproval,
          copy: _vm.pageCopy.enrollHeader
        },false)),_c('div',{staticClass:"form"},[_c('FormErrors',_vm._b({ref:"enrollmentFormErrors"},'FormErrors',{
            errors: _vm.errors,
            copy: _vm.pageCopy.formErrors
          },false)),_c('form',{ref:"enrollmentForm",staticClass:"login-form",attrs:{"novalidate":"true"},on:{"submit":function($event){$event.preventDefault();}}},[_c('ion-grid',[_c('hr',{staticClass:"margin-bottom-twenty"}),_c('GeneralInformation',_vm._b({staticClass:"prime-enrollment-form-section",on:{"input":function($event){return _vm.handleInput($event)}}},'GeneralInformation',{
                firstName: _vm.enrollment.firstName,
                lastName: _vm.enrollment.lastName,
                suffix: _vm.enrollment.suffix,
                copy: _vm.pageCopy.generalInformation,
                isApproval: _vm.isApproval,
                callRequested: _vm.callRequested
              },false)),_c('CommunicationPrefContact',_vm._b({staticClass:"prime-enrollment-form-section",on:{"input":function($event){return _vm.handleInput($event)}}},'CommunicationPrefContact',{
                email: _vm.enrollment.email,
                smsPhoneNumber: _vm.enrollment.smsPhoneNumber,
                voicePhoneNumber: _vm.enrollment.voicePhoneNumber,
                copy: _vm.pageCopy.communicationPrefContact
              },false)),_c('CommunicationPrefTimes',_vm._b({staticClass:"prime-enrollment-form-section",on:{"input":function($event){return _vm.handleInput($event)}}},'CommunicationPrefTimes',{
                copy: _vm.pageCopy.communicationPrefTimes,
                timeZone: _vm.enrollment.timeZone
              },false)),_c('SubmitEnrollment',_vm._b({ref:"enrollmentFormApprove",staticClass:"prime-enrollment-form-section",on:{"error":function($event){return _vm.handleErrors($event)},"submitClick":function($event){return _vm.handleSubmitClick('enrollmentFormApprove')}}},'SubmitEnrollment',{
                isApproval: _vm.isApproval,
                isDenial: false,
                enrollment: _vm.enrollment,
                enrollmentWrapper: _vm.enrollmentWrapper,
                restMethod: _vm.restMethod,
                url: _vm.url,
                token: _vm.token,
                copy: _vm.pageCopy.submitEnrollment
              },false)),(_vm.isApproval)?_c('SubmitEnrollment',_vm._b({ref:"enrollmentFormDeny",staticClass:"prime-enrollment-form-section",on:{"error":function($event){return _vm.handleErrors($event)},"submitClick":function($event){return _vm.handleSubmitClick('enrollmentFormDeny')}}},'SubmitEnrollment',{
                isDenial: true,
                enrollment: _vm.enrollment,
                enrollmentWrapper: _vm.enrollmentWrapper,
                restMethod: _vm.restMethod,
                url: _vm.url,
                token: _vm.token,
                copy: _vm.pageCopy.submitEnrollment
              },false)):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }