<template>
  <div>
    <h4>Communication Preferences</h4>
    <div class="prime-form-large-group-container">
      <div class="prime-form-large-group-info">
        <h6 class="form-field-label">Phone Number - Text Messages</h6>
        <p class="text-muted">
          This is the phone number at which you would like to receive text messages. It must support SMS messaging.
        </p>
      </div>
      <div class="prime-form-large-group-fields">
        <input id="smsPhoneNumber" class="form-control" type="text" placeholder="(xxx) xxx-xxxx" :value="smsPhoneNumber" @input="emitInput($event)" />
      </div>
    </div>
    <div class="prime-form-large-group-container">
      <div class="prime-form-large-group-info">
        <h6 class="form-field-label">Phone Number - Voice Calls</h6>
        <p class="text-muted">
          This is the phone number at which you would like to receive voice calls from the system, and live updates from the care team.
        </p>
      </div>
      <div class="prime-form-large-group-fields">
        <input id="voicePhoneNumber" class="form-control" type="text" placeholder="(xxx) xxx-xxxx" :value="voicePhoneNumber" @input="emitInput($event)" />
      </div>
    </div>
    <div class="prime-form-large-group-container">
      <div class="prime-form-large-group-info">
        <h6 class="form-field-label">Primary Email Address</h6>
        <p class="text-muted">
          Your email address at which you will receive reminders, notifications, and educational content.
        </p>
      </div>
      <div class="prime-form-large-group-fields">
        <input id="email" class="form-control" type="email" placeholder="Email" :value="email" @input="emitInput($event)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommunicationPrefContact",
  props: {
    email: String,
    smsPhoneNumber: String,
    voicePhoneNumber: String
  },
  data() {
    return {};
  },
  methods: {
    emitInput(event) {
      const emitEvent = {
        name: event.target.id,
        value: event.target.value
      };
      this.$emit("input", emitEvent);
    }
  }
};
</script>

<style scoped></style>
