<template>
  <ion-content scroll-x="true">
    <EnrollForm
      v-if="enrollmentType"
      v-bind="{
        restMethod,
        isApproval,
        url,
        authToken
      }"
    />
  </ion-content>
</template>

<script>
import EnrollForm from "@/components/Enrollment/FormEnrollment.vue";

export default {
  name: "Enrollment",
  components: {
    EnrollForm
  },
  props: {
    enrollmentType: String
  },
  data() {
    return {
      beneficiaryId: 1,
      restMethod: this.enrollmentType === "approval" ? "put" : "post",
      isApproval: this.enrollmentType === "approval" ? true : false,
      url: this.enrollmentType === "approval" ? document.config.enrollmentApi : document.config.enrollmentApiPublic,
      authToken: ""
    };
  },
  async created() {
    if (this.enrollmentType === "approval") {
      this.authToken = await this.$authState.accessToken();
    }
  }
};
</script>

<style scoped></style>
